import './App.css';
import { Banner } from './components/Banner';
import { Contact } from "./components/Contact";
import { NavBar } from './components/NavBar';
import { Projects } from './components/Projects';
import { Team } from './components/Team';
import { Footer } from './components/Footer'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Banner/>
      <Team/>
      <Projects/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
