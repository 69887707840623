import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/glich.png";
import projImg2 from "../assets/img/dashboard.png";
import projImg3 from "../assets/img/Android.png";
import colorSharp2 from "../assets/img/pag1.png";

import TrackVisibility from 'react-on-screen';

export const Projects = () =>{
    
    const projects = [
        {
            title: "C&Log",
            description: "Diseño de dispositivos IOT",
            imgUrl: projImg1,
        },
        {
            title: "Dashboard",
            description: "Desarrollo web",
            imgUrl: projImg2,
        },
        {
            title: "App",
            description: "Desarrollo movil",
            imgUrl: projImg3,
        }
    ]; 

    return (
        <section className="project" id="project">
            {/* <img className="background-image-right" src={colorSharp2}/> */}
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                        {({ isVisible }) =>
                        <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                            <h2>Proyectos</h2>
                            <p>Nuestro compromiso con la calidad y la excelencia se refleja en cada proyecto que emprendemos. Nos esforzamos por ofrecer soluciones tecnológicas avanzadas, confiables y escalables que se adapten a las necesidades cambiantes de la industria y brinden resultados tangibles a nuestros clientes.</p>
                            <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Componentes</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="second">App</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="third">C&Log</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">Dashboard</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {
                                            projects.map((project, index) => {
                                                return (
                                                <ProjectCard  key={index} {...project}/>
                                                )
                                            })
                                            }
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <p>Este sistema cuenta con servicios
                                            multiplataforma (aplicaciones
                                            android), ya que se desea la mejor
                                            comodidad para nuestros clientes
                                            y los usuarios del sistema</p>
                                    </Tab.Pane>
                                    
                                    <Tab.Pane eventKey="third">
                                        <p>E&IS Colombia, pensando en
                                            el beneficio de nuestros
                                            clientes y en mejorar el control
                                            de calidad de sus productos,
                                            diseñó y desarrolló la familia
                                            de dispositivos Data Logger
                                            C&Log.
                                            Dispositivos capaces de obtener,
                                            registrar y visualizar en tiempo real
                                            información relevante de humedad y
                                            temperatura, basados en las tecnologías
                                            IoT, se brinda un servicio cloud, que
                                            proporciona de manera oportuna
                                            información crucial para el monitoreo de
                                            datos.</p>
                                    </Tab.Pane>
                                        
                                    <Tab.Pane eventKey="fourth">
                                        <p>Nuestra Web, proporciona una herramienta
                                        administrativa confiable, robusta y ágil, la cual
                                        utilizando las ultimas tecnologías se actualiza
                                        constantemente para brindar nuevos servicios a
                                        nuestros clientes.</p>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            
        </section>
    )
}