import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import briant from "../assets/img/briant.jpeg"
import pipe from "../assets/img/pipe.jpeg"
import juan from "../assets/img/juan.jpeg"
import colorSharp from "../assets/img/pag.png"

export const Team = () => {
    
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="team" id="team">
            <Container>
                <Row>
                    <Col>
                    <div className="team-bx">
                        <h2>
                            Equipo
                        </h2>
                        <p>
                        Con un equipo de expertos apasionados por la tecnología y la excelencia, nos dedicamos a diseñar y desarrollar sistemas IoT de vanguardia que impulsan la eficiencia, la productividad y el desarrollo en el entorno industrial.
                        </p>
                        <Carousel responsive={responsive} infinite={true} className="team-slider">
                            <div className="item">
                                <img className="box" src={briant} alt="Image"/>
                                <h5>Briant Moscoso Caro</h5>
                            </div>
                            <div className="item">
                                <img className="box" src={pipe} alt="Image"/>
                                <h5>Andres Felipe Arias</h5>
                            </div>
                            <div className="item">
                                <img className="box" src={juan} alt="Image"/>
                                <h5>Juan Felipe Padilla</h5>
                            </div>
                        </Carousel>
                    </div>
                    </Col>
                </Row>
            </Container>
            {/* <img className="background-image-left" src={colorSharp} /> */}
        </section>
    )
}